import React from "react";
import './components.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";  // Import PropTypes
import ZcToken from '../images/zc-token.png';
import { Link } from 'react-router-dom';

// Define PropTypes for buttons
const buttonPropTypes = PropTypes.shape({
    type: PropTypes.string,
    icon: PropTypes.string,
    buttonText: PropTypes.string,
    link: PropTypes.string,
    target: PropTypes.string,
});

// Define PropTypes for each data item
const dataItemPropTypes = PropTypes.shape({
    textColor: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    milestone: PropTypes.number,
    milestoneDesc: PropTypes.string,
    point: PropTypes.number,
    maxPoint: PropTypes.number,
    button: PropTypes.arrayOf(buttonPropTypes) // Optional, as it's not always present
});

// Define PropTypes for the main tableData object
const tableDataPropTypes = PropTypes.shape({
    headerTitle: PropTypes.string,
    headerTitleColor: PropTypes.string,
    headerSubTitle: PropTypes.string,
    headerSubTitle2: PropTypes.string,
    tableType: PropTypes.string,
    tableSubHeader: PropTypes.string,
    tableSubHeaderLink: PropTypes.string,
    tableSubHeaderButtonText: PropTypes.string,
    tableSubHeaderInfo: PropTypes.string,
    mobileType: PropTypes.string,
    data: PropTypes.arrayOf(dataItemPropTypes)
});


// Define the expected prop types
TableCarousel.propTypes = {
    tableData: tableDataPropTypes.isRequired
};

export default function TableCarousel({ tableData }) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className="table-carousel mb-10">
            {(tableData.headerTitle || tableData.headerSubTitle || tableData.headerSubTitle2) && (
                <div className="table-header flex w-[100%] items-center">
                    <div className="main-title w-full">
                        <div className={` w-fit bg-[#9087FF66] rounded-t-2xl`} style={{ color: tableData.headerTitleColor }}>{tableData.headerTitle}</div>
                    </div>
                    <div className="sub-title w-1/4 flex justify-center">{tableData.headerSubTitle}</div>
                    <div className="sub-title w-1/4 flex justify-center">{tableData.headerSubTitle2}</div>
                </div>
            )}

            {/* <div className="table-type">Table Type: {tableData.tableType}</div> */}
            <div className={`table-container  ${tableData.tableType}`}>
                {tableData.tableType === 'main' && (
                    <div className="sub-header flex flex-col md:flex-row md:justify-between items-center">
                        <div className="flex flex-col items-center md:items-start">
                            <span>{tableData.tableSubHeader}</span>
                            {tableData.tableSubHeaderInfo && (
                                <div className="info">
                                    <div className="info-text">{tableData.tableSubHeaderInfo}</div>
                                </div>
                            )}
                        </div>
                        {tableData.tableSubHeaderLink && (
                            <Link to={tableData.tableSubHeaderLink} className="btn btn-secondary">{tableData.tableSubHeaderButtonText}</Link>
                        )}
                    </div>
                )}
                {tableData.data.map((item, index) => (
                    <div key={index} className="table-item flex w-[100%] items-center">
                        <div className="item main w-full flex items-center">
                            <div className="icon">
                                <img alt="" src={item.icon} className="" />
                            </div>
                            <div className="flex flex-col">
                                <div className="item-title" style={{ color: item.textColor }}>{item.title}</div>
                                <div className="item-desc">{item.subTitle}</div>
                            </div>
                        </div>

                        {/* <div className="item w-1/4 flex items-center justify-center">
                            <div className="icon">
                                <img alt="" src={ZcToken} className="" />
                            </div>
                            <div className="flex flex-col">
                                <div className="item-title">{item.milestone}</div>
                                <div className="item-desc">{item.milestoneDesc}</div>
                            </div>
                        </div> */}

                        <div className="item w-1/4 flex justify-center items-center">
                            <div>{item.button && item.button.length > 0 ? (
                                item.button.map((btn, btnIndex) => (
                                    <Link target={btn.target} key={btnIndex} to={btn.link} className={`btn btn-${btn.type}`}>
                                        {btn.icon &&
                                            <div className="icon">
                                                <img src={btn.icon} alt={btn.buttonText} />
                                            </div>
                                        }
                                        {btn.buttonText}
                                    </Link>
                                ))
                            ) : (
                                <div className="flex items-center">
                                    <div className="icon">
                                        <img alt="" src={ZcToken} className="" />
                                    </div>
                                    <div className="">
                                        {item.point}
                                        {/* {item.maxPoint && (
                                            <span>/{item.maxPoint}</span>
                                        )} */}
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                ))}

                {/* Mobile View */}
                <div className={`slider-container ${tableData.mobileType}`}>
                    <Slider {...settings}>
                        {tableData.data.map((item, index) => (
                            <div key={index} className="slider-item flex w-[100%] items-center">

                                <div className="title flex items-center justify-center mb-5 px-5">
                                    <img alt="" src={item.icon} className="icon me-2" />
                                    <div className="item-title" style={{ color: item.textColor }}>{item.title}</div>
                                    <img alt="" src={item.icon} className="icon ms-2" />
                                </div>

                                <div className="milestone flex items-center justify-center py-5">
                                    <img alt="" src={ZcToken} className="me-2" />

                                    <div className="flex flex-col">
                                        {/* <div>
                                            <div className="item-title">Milestone</div>
                                            <div className="item-value">{item.milestone}</div>
                                            <div className="item-desc">{item.milestoneDesc}</div>
                                        </div> */}
                                        {item.point || item.maxPoint && (
                                            <div className="flex flex-col items-start text-left mt-2">
                                                <div className="item-title">Points</div>
                                                <div className="item-value">
                                                    {item.point}
                                                    {/* {item.maxPoint && (
                                                        <span>/{item.maxPoint}</span>
                                                    )} */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="desc text-center px-4">{item.subTitle}</div>

                                <div className="button flex justify-center mt-5">
                                    <div>{item.button && item.button.length > 0 ? (
                                        item.button.map((btn, btnIndex) => (
                                            <Link target={btn.target} key={btnIndex} to={btn.link} className={`btn btn-${btn.type}`}>
                                                {btn.icon &&
                                                    <div className="icon">
                                                        <img src={btn.icon} alt={btn.buttonText} />
                                                    </div>
                                                }
                                                {btn.buttonText}
                                            </Link>
                                        ))
                                    ) : ''}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}