import React, { useEffect, useState, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import "./Home.scss";
import { useWallet } from '../src/Context/WalletContext';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Add these imports
import Spaceship1 from './images/portal/spaceship-1.webp';
import Spaceship2 from './images/portal/spaceship-3.webp';
import Spaceship3 from './images/portal/spaceship-2.webp';
import EcoFA from './images/portal/eco-fa.webp';
import EcoHoldstation from './images/portal/eco-holdstation.webp';
import EcoKaira from './images/portal/eco-kaira.webp';
import EcoMicro3 from './images/portal/eco-micro3.webp';
import EcoZns from './images/portal/eco-zns.webp';
import EcoDelabs from './images/portal/eco-delabs.webp';
import EcoAethir from './images/portal/eco-aethir.webp';
import EcoHypercomic from './images/portal/eco-hypercomic.webp';
import EcoPeaai from './images/portal/eco-peaai.webp';
import EcoPrometheuz from './images/portal/eco-prometheuz.webp';
import EcoZeek from './images/portal/eco-zeek.webp';
import GameBTFD from './images/portal/game-btfd.webp';
import GameEVM from './images/portal/game-evm.webp';
import GameChooChoo from './images/portal/game-choochoo.webp';
import GameSugarRush from './images/portal/game-sugarrush.webp';
import GamePepeKingdom from './images/portal/game-pepekingdom.webp';
import MenuBridge from './images/portal/menu-bridge.webp';
import MenuCandyPoints from './images/portal/menu-candypoints.webp';
import MenuExplorer from './images/portal/menu-explorer.webp';
import MenuFaucet from './images/portal/menu-faucet.webp';
import MenuQuest from './images/portal/menu-quest.webp';
import GuideMetamask from './images/portal/guide-metamask.webp';
import GuideEarn from './images/portal/guide-earn.webp';
import GuideGames from './images/portal/guide-games.webp';
import GuideFreeSepeth from './images/portal/guide-free-sepeth.webp';
import CounterTitle from './images/portal/counter-title.png';
import CounterTitleLeft from './images/portal/counter-title-left.webp';
import CounterTitleRight from './images/portal/counter-title-right.webp';
import CounterFrameLeft from './images/portal/counter-frame-left.webp';
import CounterFrameRight from './images/portal/counter-frame-right.webp';

function AnimatedNumber({ n }) {
  const prevN = useRef(n);
  const { number } = useSpring({
    from: { number: prevN.current },
    to: { number: n },
    config: {
      duration: 10000,  // Increased duration to 10 seconds
      easing: t => t * (2 - t),  // Ease-out function for smoother deceleration
    },
    onRest: () => {
      prevN.current = n;
    },
  });
  return <animated.span>{number.to((n) => Math.floor(n).toString())}</animated.span>;
}

export default function Home() {
  //const { walletAddress, isConnected, connectWallet } = useWallet(); // Use the wallet context
  const [totalWallets, setTotalWallets] = useState(null);

  useEffect(() => {
    // Get the hash from the URL
    const hash = window.location.hash;
    if (hash) {
      // Scroll to the element with the corresponding ID
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  // Add this new function for API calls
  async function sendRequest(data, url, method = "post", params = null) {
    const config = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    if (params) config.params = params;
    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  }

  const fetchTotalWallets = async () => {
    try {
      const response = await sendRequest(
        { action: "getTotalUsers" },
        `${process.env.REACT_APP_API_URL}`
      );

      if (response.status === 200 && response.data) {
        setTotalWallets(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching total wallets:", error);
    }
  };

  useEffect(() => {
    fetchTotalWallets(); // Initial fetch

    // Set up interval to fetch total wallets every 20 seconds
    const intervalId = setInterval(fetchTotalWallets, 20000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const toolsContent = {
    type: 1,
    cardname: 'tools',
    // subTitle: 'Welcome To The',
    title: 'Tools',
    // descTop: 'Your One-stop Shop For Everything',
    descBottom: 'Click here, Do Stuff, And Pretend You\'re A Wizard!',
    details: [
      {
        name: 'Bridge',
        desc: 'Transfer assets seamlessly',
        img: MenuBridge,
        link: 'https://sepolia.bridge.zkcandy.io/bridge/',
      },
      {
        name: 'Explorer',
        desc: 'View network transactions',
        img: MenuExplorer,
        link: 'https://sepolia.explorer.zkcandy.io/',
      },
      {
        name: 'Faucet',
        desc: 'Ape with sepolia eth',
        img: MenuFaucet,
        link: 'https://faucet.zkcandy.io/',
      },
    ],
    caption: ''
  };

  const guideContent = {
    type: 2,
    cardname: 'guide',
    subTitle: '"How To"',
    title: 'Master Life Hacks With',
    descTop: 'Watch | Learn | Conquer',
    descBottom: '',
    details: [
      {
        name: 'Connect To Metamask',
        desc: '',
        img: GuideMetamask,
        link: 'https://zkcandy.medium.com/connecting-your-metamask-wallet-to-zkcandy-public-testnet-80fff358423a',
        // languages: [
        //   {
        //     lang: 'KR',
        //     link: '#',
        //   },
        //   {
        //     lang: 'JP',
        //     link: '#',
        //   },
        //   {
        //     lang: 'CN',
        //     link: '#',
        //   },
        //   {
        //     lang: 'VN',
        //     link: '#'
        //   },
        // ],
      },
      {
        name: 'Get Free Test Eth',
        desc: '',
        img: GuideFreeSepeth,
        link: 'https://zkcandy.medium.com/how-to-get-sepeth-to-start-interacting-with-zkcandy-testnet-be079a1364ab',
        // languages: [
        //   {
        //     lang: 'KR',
        //     link: '#',
        //   },
        //   {
        //     lang: 'JP',
        //     link: '#',
        //   },
        //   {
        //     lang: 'CN',
        //     link: '#',
        //   },
        //   {
        //     lang: 'VN',
        //     link: '#'
        //   },
        // ],
      },
      {
        name: 'Play games',
        desc: '',
        img: GuideGames,
        link: 'https://zkcandy.medium.com/how-to-play-games-in-zkcandy-voyage-testnet-5486566fe2c4',
        // languages: [
        //   {
        //     lang: 'KR',
        //     link: '#',
        //   },
        //   {
        //     lang: 'JP',
        //     link: '#',
        //   },
        //   {
        //     lang: 'CN',
        //     link: '#',
        //   },
        //   {
        //     lang: 'VN',
        //     link: '#'
        //   },
        // ],
      },
      {
        name: 'Earn Points',
        desc: '',
        img: GuideEarn,
        link: 'https://zkcandy.medium.com/how-to-earn-points-in-zkcandy-voyage-public-testnet-122d0166fe01',
        // languages: [
        //   {
        //     lang: 'KR',
        //     link: '#',
        //   },
        //   {
        //     lang: 'JP',
        //     link: '#',
        //   },
        //   {
        //     lang: 'CN',
        //     link: '#',
        //   },
        //   {
        //     lang: 'VN',
        //     link: '#'
        //   },
        // ],
      },
    ],
    caption: 'less stress | more wins | zero adulting crises',
  };

  const ecosystemContent = {
    type: 3,
    cardname: 'ecosystem',
    subTitle: '',
    title: 'Ecosystem',
    descTop: 'Where innovation meets opportunity.',
    descBottom: 'Cutting-edge solution | unique ideas | A thriving digital ecosystem',
    details: [
      {
        name: 'Holdstation',
        desc: '',
        img: EcoHoldstation,
        // link: 'https://holdstation.com/',
      },
      {
        name: 'Footprint Analytics',
        desc: '',
        img: EcoFA,
        // link: 'https://www.footprint.network/',
      },
      {
        name: 'ZNS Connect',
        desc: '',
        img: EcoZns,
        // link: 'https://v3.znsconnect.io/',
      },
      {
        name: 'Mirco',
        desc: '',
        img: EcoMicro3,
        // link: 'https://micro3.io/',
      },
      {
        name: 'Kaira',
        desc: '',
        img: EcoKaira,
        // link: 'https://kaira.network/',
      },
      {
        name: 'Delabs',
        desc: '',
        img: EcoDelabs,
        // link: 'https://delabs.gg/',
      },
      {
        name: 'Promethuez',
        desc: '',
        img: EcoPrometheuz,
        // link: 'https://prometheuz.io/',
      },
      {
        name: 'Zeek Coin',
        desc: '',
        img: EcoZeek,
        // link: 'https://zeekcoin.com/',
      },
      {
        name: 'Hypercomic',
        desc: '',
        img: EcoHypercomic,
        // link: 'https://play.hypercomic.io/',
      },
      {
        name: 'Pea.AI',
        desc: '',
        img: EcoPeaai,
        // link: 'https://www.pea.ai/',
      },
      {
        name: 'Aethir',
        desc: '',
        img: EcoAethir,
        // link: 'https://aethir.com/',
      },
    ],
    caption: '',
  };

  const gameContent = {
    type: 2,
    cardname: 'game',
    subTitle: 'Games',
    title: '',
    descTop: 'Level up your digital empire, mint your wins,',
    descBottom: 'and flex your crypto-savvy skills. ',
    details: [
      // {
      //   name: 'BTFD.WTF',
      //   desc: '',
      //   img: GameBTFD,
      //   link: 'https://btfd.wtf/',
      // },
      {
        name: 'Sugar \n Rush',
        desc: '',
        img: GameSugarRush,
        link: 'https://sugarrush-testnet.zkcandy.io/',
      },
      {
        name: 'EVM Warfare',
        desc: '',
        img: GameEVM,
        link: 'https://evmwarfare.com/',
      },
      {
        name: 'Choo Choo Spirit',
        desc: '',
        img: GameChooChoo,
        link: 'https://choochoospirits.io/',
      },
      {
        name: 'Pepe Kingdom',
        desc: '',
        img: GamePepeKingdom,
        link: 'https://t.me/pepe_kingdom_bot',
      },
    ],
    caption: 'Blockchain? \n More like game-on!',
  };

  const CardWithHeader = ({ cardWithHeader }) => (
    <div id={cardWithHeader.cardname} className={`portal-header-frame type-${cardWithHeader.type} card-${cardWithHeader.cardname}`}>
      <div className="portal-frame">
        <div className="sub-title text-center">{cardWithHeader.subTitle}</div>
        <div className="title text-center">{cardWithHeader.title}</div>

        <div className="text-desc-top text-center">{cardWithHeader.descTop}</div>
        <div className="text-desc-bottom text-center">{cardWithHeader.descBottom}</div>


        <div className="cards flex flex-wrap justify-center -mx">
          {cardWithHeader.details.map((card, index) => (
            <div key={index} className={
              cardWithHeader.cardname === 'game'
                ? 'w-1/2 md:w-1/4 lg:w-1/4 px-3'
                : cardWithHeader.cardname === 'guide'
                  ? 'w-full md:w-1/2 px-3'
                  : cardWithHeader.cardname === 'ecosystem'
                    ? 'lg:w-1/6 md:w-1/4 w-1/3'
                    : 'w-1/2 md:w-1/3 lg:w-1/3 px-3'
            }>
              <div className="box small" rel="noopener noreferrer">
                <a className="box-thumb" href={card.link} target="_blank">
                  <img src={card.img} alt="" className="" />
                </a>
                {cardWithHeader.cardname === 'guide' ? (
                  <div className="box-desc">
                    <a className="title" href={card.link} target="_blank">{card.name}</a>
                    {card.languages != null && (
                      <div className='languages flex flex-wrap'>
                        {card.languages.map((lang, langIndex) => (
                          <a href={lang.link} className='language border-solid border-2 border-white me-2 mb-2 px-2' key={langIndex}>
                            {lang.lang}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <a className="box-desc" href={card.link} target="_blank">
                    <div className="title">{card.name}</div>
                    <div className="sub-title">{card.desc}</div>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
        {cardWithHeader.type === 2 && (<div className="caption flex text-center justify-center">{cardWithHeader.caption}</div>)}
      </div>
    </div>
  );

  return (
    <main className="home font-normal leading-7">
      <div className="container m-auto">
        <div className="heading">
          <h1 className="mb-5 md:mb-4">ZKCANDY VOYAGE <br />TESTNET <br className="block md:hidden" /> IS NOW LIVE!</h1>
          <p>Super Fast ZK Chain Hyperscaling GameFi and MemeFi</p>

          {/* Updated Total Wallets section with animation */}
          {totalWallets !== null && (
            // <div className="total-wallets-container">
            //   <div className="total-wallets-content">
            //     <h2 className="total-wallets-title">TOTAL WALLETS</h2>
            //     <p className="total-wallets-count">
            //       <AnimatedNumber n={totalWallets} />
            //     </p>
            //   </div>
            // </div>
            <div className="flex justify-center mt-20">
              <div className="total-wallets-frame mt-10 w-[700px] flex flex-col items-center">
                <div className="total-wallets-bg relative flex items-center">
                  <img className='total-wallet-header w-[70%] -top-[30%] xl:-top-[35%]' src={CounterTitle} />
                  <img className='h-[100%] absolute right-[78%]' src={CounterFrameLeft} />
                  <img className='h-[100%] absolute left-[85%]' src={CounterFrameRight} />

                  <div className="total-wallets-count">
                    <div className="text relative z-10">
                      <AnimatedNumber n={totalWallets} />
                    </div>
                    <div className="text-bg">
                      <AnimatedNumber n={totalWallets} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* ... keep your existing spaceships section ... */}

          <div className="spaceships">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <img
                  src={Spaceship1}
                  alt="spaceship"
                  className="spaceship-1"
                />
                <img
                  src={Spaceship2}
                  alt="spaceship"
                  className="spaceship-2"
                />
              </div>
              <div className="flex items-center">
                <img
                  src={Spaceship3}
                  alt="spaceship"
                  className="spaceship-3"
                />
                <img
                  src={Spaceship1}
                  alt="spaceship"
                  className="spaceship-4"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="menu-container">
          <div className={`portal-header-frame type-1 card-welcome`}>
            <div className="portal-frame">
              <div className="title text-center mb-4">Welcome To ZKcandy Portal</div>

              <div className='card'>
                <div className="flex flex-wrap -mx">
                  <div className="w-full md:w-1/2 px-3">
                    <Link className="box big" to="/quests">
                      <div className="box-thumb">
                        <img
                          src={MenuQuest}
                          alt="Quests"
                          className=""
                        />
                      </div>
                      <div className="box-desc">
                        <div className="title">Quests</div>
                        <div className="sub-title">Participate to earn candy points</div>
                      </div>
                    </Link>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <Link className="box big" to="/my-points">
                      <div className="box-thumb">
                        <img
                          src={MenuCandyPoints}
                          alt="Candy Points"
                          className="Candy Points"
                        />
                      </div>
                      <div className="box-desc">
                        <div className="title">Candy Points</div>
                        <div className="sub-title">See all your points here</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CardWithHeader cardWithHeader={toolsContent} />
          <CardWithHeader cardWithHeader={guideContent} />
          <CardWithHeader cardWithHeader={gameContent} />
          <CardWithHeader cardWithHeader={ecosystemContent} />
        </div>
      </div>
    </main>
  );
}